
import React, { useCallback, useEffect } from 'react'
import {
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Menu,
  Autocomplete,
  TextField
} from '@mui/material'
import {
  Column,
  PaddingBox,
  Row,
  SpacedRow
} from '../../../reusable/Scaffolds'
import {
  convertDateWithDayAndYear,
  upperCaseFirstLetter,
  validUrl,
  isIndividualAssigned
} from '../../../../utils/helper_functions'
import { useNavigate } from 'react-router-dom'
import {
  PrimaryButton,
  DropDownButton,
  CloseButton
} from '../../../reusable/Buttons'
import { get, put } from '../../../../utils/api'
import theme from '../../../../utils/theme'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  M3BodyLarge,
  M3BodySmall,
  M3LabelMedium
} from '../../../reusable/TextStyles'
import {
  CANCELLED,
  OPEN,
  SHIFT_STATES,
  assignmentGroupType,
  bonusList
} from '../../../../utils/constants'
import {
  CommonTextField,
  TimeRangePicker
} from '../../../reusable/TextField'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ConfirmBlacklistForm from '../../../forms/ConfirmBlacklistForm'
import RemoveBlacklistForm from '../../../forms/RemoveBlacklistForm'
import AddBlacklistForm from '../../../forms/AddBlacklistForm'
import { compareTimePickerValues } from '../../../../utils/time'
import { decideTagType } from '..'
import { CustomAvatar } from '../../../Layout'


const ShiftDetailTab = ({
  shiftData,
  educatorInfo,
  statusShift,
  setAlert,
  reload,
  currentUser,
  orgId,
  backButtonHandler
}) => {
  const navigate = useNavigate()
  const [valueEducatorPool, setEducatorPool] = React.useState(
    shiftData.public ? 'QuickCare only' : 'Private only'
  )
  const [shiftDataState, setShiftDataState] = React.useState(shiftData)
  const [assignOptions, setAssignOptions] = React.useState([])
  const [individualAssigned, setIndividualAssigned] = React.useState(false)
  const [validEducators, setValidEducators] = React.useState([])

  // Modal blacklist
  const [reasonAddBlacklist, setReasonAddBlacklist] = React.useState('')
  const [openAddBlacklistModal, setOpenAddBlacklistModal] = React.useState(false)
  const [openRemoveBlacklistModal, setOpenRemoveBlacklistModal] = React.useState(false)
  const [openConfirmBlacklistModal, setOpenConfirmBlacklistModal] = React.useState(false)

  const handleAddBlacklist = (reason) => {
    setReasonAddBlacklist(reason)
    setOpenAddBlacklistModal(false)
    setOpenConfirmBlacklistModal(true)
  }

  // Menu card filled 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  function validateFormCreate() {
    const validateEndTimeSmallerThanStartTime =
      compareTimePickerValues(shiftDataState.shift_start, shiftDataState.shift_end) === -1
    const validateUserId = individualAssigned
      ? !!shiftDataState.educator_id
      : true
    return (
      shiftDataState.shift_start !== '' &&
      shiftDataState.shift_end !== '' &&
      validateEndTimeSmallerThanStartTime &&
      validateUserId &&
      shiftDataState.assigment_types
    )
  }
  const changeEducatorPool = (event) => {
    setShiftDataState({
      ...shiftDataState,
      assigment_types: event.target.value,
      educator_id: isIndividualAssigned(event.target.value)? shiftDataState.educator_id: null
    })
    setIndividualAssigned(event.target.value === 'individual-assigned')
    setEducatorPool(event.target.value)
  }

  const constShiftNoticeTitle = (shift) => {
    if (decideTagType(shift) === OPEN && shift.assigment_types === 'individual-assigned') {
      return 'Pending acceptance by'
    }
    if (decideTagType(shift) === OPEN && shift.assigment_types === 'individual-rejected') {
      return 'Declined by'
    }
    return 'Filled by'
  }

   const setEducatorId = (educatorId) => {
    setShiftDataState({
      ...shiftDataState,
      educator_id: educatorId,
      educator: validEducators.find((e) => e.id === educatorId)
    })
  }

  const fetchAssignOption = useCallback(
    async (centre_id) => {
      try {
        const res = await get(
          `/manage/organisation/${orgId}/assignment_group_options`,
          null,
          { centre_id }
        )
        setAssignOptions(res)
      } catch (error) { }
    },
    [orgId]
  )

  async function updateShiftData() {
    if (compareTime(shiftDataState.shift_start, shiftDataState.shift_end))
      return setAlert('Shift start time must be before shift end time', 'error')
    try {
      await put(`/manage/shifts`, {
        ...shiftDataState,
        centre_id: shiftDataState.centre.id,
        qualification: shiftDataState.qualification.id,
        public: valueEducatorPool === 'QuickCare only' ? true : false,
        isChangedAssignee: shiftDataState.educator_id !== shiftData.educator_id || shiftDataState.assigment_types !== shiftData.assigment_types
      })
      reload()
      setAlert('Shift updated successfully')
    } catch (error) {
      reload()
      setAlert(error.message, 'error')
    }
  }

  async function handleRemoveQuickCareEducatorFromShift() {
    try {
      await put(`/admin/shifts/${shiftDataState.id}/remove_quickcare_educator`, {
        educator_name: shiftDataState.educator.first_name + ' ' + shiftDataState.educator.last_name,
        educator_id: shiftDataState.educator.id
      })
      setAlert('Educator removed from shift successfully')
      backButtonHandler()
    } catch (error) {
      setAlert(error.message, 'error')
    }
  }
  function compareTime(startTime, endTime) {
    return startTime > endTime
  }
  function updateBonus(e) {
    setShiftDataState({ ...shiftDataState, bonus: e.target.value })
  }
  function getContentNote(statusShift) {
    switch (statusShift) {
      case OPEN:
        return 'Because the shift has already been posted, some fields cannot be edited. Please cancel the shift and create a new one, if you wish to change those fields.'
      case CANCELLED:
        return ''
      default:
        return 'This shift has been accepted by an educator and cannot be edited, nor can the assigned educator can be removed from the shift. If changes need to be made, the shift will need to be cancelled and re-posted.'
    }
  }

  function getTitleNote(statusShift) {
    switch (statusShift) {
      case OPEN:
        return 'Some fields cannot be edited'
      case CANCELLED:
        return ''
      default:
        return 'Shift cannot be edited'
    }
  }

  function getShiftAssignmentTitle(assigmentType) {
    switch (assigmentType) {
      case assignmentGroupType.invidualAssigned:
        return 'Individual educator'
      case assignmentGroupType.org:
        return shiftDataState.organisation_name ?? ''
      case assignmentGroupType.quickcare:
      case assignmentGroupType.centre:
        return upperCaseFirstLetter(assigmentType) + ' educators'
      default:
        return ''
    }
  }
  function convertDateTimeToServerFormat(time) {
    return time.$H + ':' + time.$m + ':' + time.$s
  }
  function setTimeRange(newValue) {
    setShiftDataState({
      ...shiftDataState,
      shift_start: convertDateTimeToServerFormat(newValue[0]),
      shift_end: convertDateTimeToServerFormat(newValue[1])
    })
  }
  
  const fetchOrgEducators = useCallback(async () => {
    const params = {start_date: shiftData.shift_date, end_date: shiftData.shift_date, qualification_list: shiftData.qualification.id, shift_id: shiftData.id}
    const res = await get(`/manage/organisations/${orgId}/educators`, null, params);
    setValidEducators(res);
  }, [orgId, shiftData]);

  useEffect(() => {
    setShiftDataState({
      ...shiftData,
      bonus: shiftData.bonus ? shiftData.bonus.toString() : '0'
    })

    setIndividualAssigned(isIndividualAssigned(shiftData.assigment_types))
    fetchAssignOption(shiftData.centre.id)
    fetchOrgEducators()
  }, [shiftData, fetchAssignOption, fetchOrgEducators])
 const shouldShowEducatorInfo =
   ((educatorInfo != null && isIndividualAssigned(shiftData.assigment_types)) ||
     (educatorInfo != null && shiftData.state !== SHIFT_STATES.AVAILABLE)) && !(
      shiftData.state === SHIFT_STATES.EXPIRED &&
      isIndividualAssigned(shiftData.assigment_types))
   
  return (
    <div>
      <PaddingBox />
      {shouldShowEducatorInfo && (
        <Box sx={{ m: 1, backgroundColor: theme.colors.colorEBF9FF }}>
          <ListItem
            sx={{
              backgroundColor: theme.palette.blueJeans.main,
              color:theme.palette.blueJeans.onBlueJeans
            }}
          >
            <ListItemAvatar>
              <CustomAvatar
                src={
                  educatorInfo.avatar_url !== null
                    ? educatorInfo.avatar_url
                    : null
                }
              />
            </ListItemAvatar>
            <SpacedRow>
              <ListItemText
                primary={
                  <M3LabelMedium style={{ color: theme.palette.text.main }}>
                    {constShiftNoticeTitle(shiftData)}
                  </M3LabelMedium>
                }
                secondary={
                  <React.Fragment>
                    <M3BodyLarge style={{ color: theme.palette.text.main }}>
                      {educatorInfo.first_name ?? ''}
                      {educatorInfo.prefered_name
                        ? ' (' + educatorInfo.prefered_name + ') '
                        : ' '}
                      {educatorInfo.last_name ?? ''}
                    </M3BodyLarge>
                    <M3BodySmall style={{ color: theme.palette.text.main }}>
                      {educatorInfo.qualification_level}
                    </M3BodySmall>
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <div>
                  <MoreVertIcon
                    id="icon-menu"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleOpenMenu}
                  />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                      'aria-labelledby': 'icon-menu'
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <MenuItem
                      disabled={
                        !educatorInfo.quickcare_educator ||
                        !validUrl(educatorInfo.document_url)
                      }
                      onClick={(event) => {
                        event.preventDefault()
                        window.open(educatorInfo.document_url, '_blank')
                      }}
                    >
                      View Qualification Documents
                    </MenuItem>
                    {!currentUser.is_quickcare_admin ? (
                      shiftDataState.educator &&
                      shiftDataState.educator.is_blacklisted ? (
                        <MenuItem
                          onClick={(event) => {
                            event.preventDefault()
                            handleCloseMenu()
                            setOpenRemoveBlacklistModal(true)
                          }}
                        >
                          Remove Blacklist
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={(event) => {
                            event.preventDefault()
                            handleCloseMenu()
                            setOpenAddBlacklistModal(true)
                          }}
                        >
                          Block educator
                        </MenuItem>
                      )
                    ) : (
                      <MenuItem
                        onClick={(event) => {
                          event.preventDefault()
                          handleCloseMenu()
                          handleRemoveQuickCareEducatorFromShift()
                        }}
                      >
                        Remove Educator from shift
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              </ListItemSecondaryAction>
            </SpacedRow>
          </ListItem>
        </Box>
      )}
      <PaddingBox />
      <Column>
        <M3LabelMedium>{getTitleNote(statusShift)}</M3LabelMedium>
        <M3LabelMedium style={{ fontWeight: '400' }}>
          {getContentNote(statusShift)}
        </M3LabelMedium>
        <PaddingBox />
        <Row>
          <CommonTextField
            label={'Location'}
            value={shiftData.centre.name}
            isDisable={true}
            icon={<ArrowDropDownIcon />}
          />
        </Row>
        <PaddingBox />
        <Row>
          <CommonTextField
            label={'Required qualification'}
            value={shiftData.qualification?.qualification || 'Unqualified'}
            isDisable={true}
            icon={<ArrowDropDownIcon />}
          />
          {shiftData.role && (
            <>
              <PaddingBox />
              <CommonTextField
                label={'Role'}
                value={upperCaseFirstLetter(shiftData.role)}
                isDisable={true}
                icon={<ArrowDropDownIcon />}
              />
              <PaddingBox />
            </>
          )}
        </Row>
        <PaddingBox />
        <h4>Shift time</h4>
        <Row>
          <CommonTextField
            label={'Date'}
            value={convertDateWithDayAndYear(shiftData.shift_date)}
            isDisable={true}
            icon={<ArrowDropDownIcon />}
          />
          <PaddingBox />
          <TimeRangePicker
            isDisabled={statusShift !== OPEN}
            startTime={shiftDataState.shift_start}
            endTime={shiftDataState.shift_end}
            setData={setTimeRange}
          />
        </Row>
        <h4>Shift bonus</h4>
        <Row>
          <DropDownButton
            placeholder={'Shift bonus'}
            currentValue={shiftDataState.bonus ?? '0'}
            onChange={updateBonus}
            isDisabled={statusShift !== OPEN}
            options={bonusList.map((option) => (
              <MenuItem key={option} value={option} dense>
                {'$' + option}
              </MenuItem>
            ))}
          />
        </Row>
        <h4>Assign shift to</h4>
        <Row>
          <DropDownButton
            placeholder={'Choose who can see the shift'}
            currentValue={
              isIndividualAssigned(shiftDataState.assigment_types)
                ? 'individual-assigned'
                : shiftDataState.assigment_types
            }
            onChange={changeEducatorPool}
            isDisabled={assignOptions.length === 0 || statusShift !== OPEN}
            options={assignOptions.map((option) => (
              <MenuItem key={option} value={option} dense>
                {getShiftAssignmentTitle(option)}
              </MenuItem>
            ))}
          />
        </Row>
        {individualAssigned && (
          <div>
            <h4>Choose the educator</h4>
            <Row>
              <Autocomplete
                disablePortal
                id="combo-box-educators"
                placeholder={'Search for / choose educator'}
                fullWidth={true}
                value={shiftDataState.educator}
                options={validEducators}
                disabled={statusShift !== OPEN}
                getOptionLabel={(option) => {
                  if (!option) {
                    return ''
                  }
                  return (
                    (option.first_name || '') + ' ' + (option.last_name || '')
                  )
                }}
                onChange={(event, educator) => {
                  setEducatorId(educator?.id)
                }}
                isOptionEqualToValue={(option, value) => {
                  if (!option || !value) {
                    return false
                  }
                  return option !== null && option.id === value.id
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Row>
          </div>
        )}

        <h4>Description</h4>
        <Row>
          <CommonTextField
            isMultiline={true}
            rows={3}
            label={'Description'}
            onChange={(e) => {
              setShiftDataState({
                ...shiftDataState,
                description: e.target.value
              })
            }}
            value={shiftDataState.description}
            isDisable={statusShift !== OPEN}
          />
        </Row>
        <PaddingBox />
        {statusShift === OPEN ? (
          <SpacedRow>
            <CloseButton onClick={() => navigate('/shifts')} text={'Cancel'} />
            <PrimaryButton
              text="Save changes"
              onClick={updateShiftData}
              isDisabled={!validateFormCreate()}
            />
          </SpacedRow>
        ) : null}
      </Column>
      <ConfirmBlacklistForm
        open={openConfirmBlacklistModal}
        educatorInfo={shiftDataState.educator || {}}
        educatorQualification={educatorInfo && educatorInfo.qualification_level}
        setOpen={setOpenConfirmBlacklistModal}
        setCloseModalAdd={setOpenAddBlacklistModal}
        setAlert={setAlert}
        reason={reasonAddBlacklist}
        refreshDataShift={reload}
      />
      <AddBlacklistForm
        open={openAddBlacklistModal}
        educatorInfo={shiftDataState.educator || {}}
        setOpen={setOpenAddBlacklistModal}
        handleAddBlacklist={handleAddBlacklist}
      />
      {shiftDataState.black_listed && (
        <RemoveBlacklistForm
          open={openRemoveBlacklistModal}
          educatorInfo={shiftDataState.educator || {}}
          setOpen={setOpenRemoveBlacklistModal}
          setAlert={setAlert}
          refreshShift={reload}
          blacklistedInfo={shiftDataState.black_listed}
        />
      )}
    </div>
  )
}


export default ShiftDetailTab